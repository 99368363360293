import React from "react";

import "./StepTracker.scss";
import { IStepTrackerItem, StepTrackerItem } from "./StepTrackerItem";
import { getElementOffset } from "../../../generics/dom-extensions";

export interface IStepTrackerProps {
  /** Steps data */
  items: IStepTrackerItem[];
  /** Scrollable container CSS selector (in case if it is not window) */
  scrollableContainerSelector?: string;
}

export class StepTracker extends React.Component<IStepTrackerProps> {
  // @ts-ignore
  private subscribers: Array<(listener) => void> = [];
  private componentRef = React.createRef<HTMLDivElement>();
  private containerRef = React.createRef<HTMLDivElement>();
  private anchorRef = React.createRef<HTMLElement>();
  // @ts-ignore
  private scrollableContainer: HTMLElement | null;
  private isScrollableWindow = !this.props.scrollableContainerSelector;
  private scrollTicking = false;

  public componentDidMount() {
    if (!this.isScrollableWindow) {
      this.scrollableContainer = document.querySelector(
        this.props.scrollableContainerSelector!
      );

      if (this.scrollableContainer) {
        const initialPosition = getComputedStyle(
          this.scrollableContainer
        ).position;

        if (!initialPosition || initialPosition === "static") {
          this.scrollableContainer.style.position = "relative";
        }

        this.scrollableContainer.addEventListener("scroll", this.handleScroll, {
          passive: true,
        });
      }
    } else {
      window.addEventListener("scroll", this.handleScroll, { passive: true });
    }

    if (this.componentRef.current) {
      this.componentRef.current!.style.height = `${
        this.componentRef.current.getBoundingClientRect().height
      }px`;
    }
  }

  public componentWillUnmount() {
    (this.scrollableContainer || window).removeEventListener(
      "scroll",
      this.handleScroll
    );
  }

  public render() {
    return (
      <>
        <span ref={this.anchorRef} />
        <div className="c-step-tracker" ref={this.componentRef}>
          <div
            className="c-step-tracker__container px-7 md:px-auto"
            ref={this.containerRef}
          >
            <div className="c-step-tracker__inner">
              {this.props.items.map((item, index) => (
                <div className="c-step-tracker__item" key={index}>
                  <StepTrackerItem
                    {...item}
                    itemId={index}
                    setTransformListener={this.setTransformListener}
                    unsetTransformListener={this.unsetTransformListener}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }

  private handleScroll = (e: Event) => {
    if (!this.componentRef.current) {
      return;
    }

    if (!this.scrollTicking) {
      window.requestAnimationFrame(() => this.callSubscribers(e));

      this.scrollTicking = true;
    }
  };

  private callSubscribers(e: Event) {
    const scrollOffset: number = this.isScrollableWindow
      ? window.pageYOffset
      : (e.target as HTMLElement).scrollTop;
    const currentOffset: number = this.isScrollableWindow
      ? getElementOffset(this.anchorRef.current as HTMLElement)
      : this.anchorRef.current!.offsetTop;

    this.subscribers.forEach((listener) =>
      listener(scrollOffset - currentOffset)
    );
    this.scrollTicking = false;
  }

  private setTransformListener = (itemId: number, listener: () => void) => {
    this.subscribers[itemId] = listener;
  };

  private unsetTransformListener = (itemId: number) => {
    this.subscribers.splice(itemId, 1);
  };
}
